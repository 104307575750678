import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField } from "@material-ui/core";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/SnackbarContent";
import {
  getQuestion,
  createQuestion,
  updateQuestion,
  clearQuestionsFlag
} from "../../redux/actions/QuestionActions";
import CardIcon from "components/Card/CardIcon";

class ManageQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      openSnackBar: false,
      response: "",
      snackBarVariant: "error",
      uuid: props.location.state && props.location.state.uuid,
      isEdit: props.location.state && props.location.state.isEdit
    };
  }

  componentDidMount() {
    const { getQuestion, client } = this.props;
    const { uuid } = this.state;
    uuid && getQuestion({ uuid }, client);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      clearQuestionsFlag,
      getQuestionSuccess,
      questionData,
      getQuestionError,
      updateQuestionSuccess,
      updateQuestionError,
      createQuestionSuccess,
      createQuestionError
    } = props;

    if (getQuestionSuccess) {
      clearQuestionsFlag();
      return {
        question: questionData.question
      };
    }

    if (getQuestionError) {
      clearQuestionsFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, please try again later!",
        snackBarVariant: "error"
      };
    }

    if (updateQuestionSuccess) {
      clearQuestionsFlag();
      props.history.push("/admin/questions");
    }

    if (updateQuestionError) {
      clearQuestionsFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, please try again later!",
        snackBarVariant: "error"
      };
    }

    if (createQuestionSuccess) {
      clearQuestionsFlag();
      props.history.push("/admin/questions");
    }

    if (createQuestionError) {
      clearQuestionsFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, please try again later!",
        snackBarVariant: "error"
      };
    }

    return null;
  }

  editQuestion = () => {
    const { uuid, question } = this.state;
    const { client, updateQuestion } = this.props;
    updateQuestion({ uuid, question }, client);
  };

  addQuestions = () => {
    const { question } = this.state;
    const { client, createQuestion } = this.props;
    createQuestion({ question }, client);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { classes } = this.props;
    const {
      question,
      openSnackBar,
      response,
      snackBarVariant,
      isEdit
    } = this.state;
    return (
      <>
        <Snackbar
          open={openSnackBar}
          handleClose={this.handleCloseSnackbar}
          variant={snackBarVariant}
          message={response}
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardIcon color="info">
                  <QuestionAnswerIcon />
                </CardIcon>
              </CardHeader>
              <CardBody className={classes.subscriptionCard}>
                <GridContainer>
                  <GridItem sm={2} xs={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      Question
                    </FormLabel>
                  </GridItem>
                  <GridItem sm={10} xs={12}>
                    <TextField
                      fullWidth
                      label="Please Enter Question"
                      className={classes.textField}
                      name="question"
                      margin="normal"
                      variant="outlined"
                      value={question}
                      onChange={this.handleChange}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                {isEdit ? (
                  <Button
                    onClick={this.editQuestion}
                    variant="contained"
                    color="info"
                    style={{
                      marginTop: 40
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    onClick={this.addQuestions}
                    variant="contained"
                    color="info"
                    style={{
                      marginTop: 40
                    }}
                  >
                    Add
                  </Button>
                )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
ManageQuestion.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    questionData: state.questionReducer && state.questionReducer.questionData,
    getQuestionSuccess:
      state.questionReducer && state.questionReducer.getQuestionSuccess,
    getQuestionError:
      state.questionReducer && state.questionReducer.getQuestionError,
    createQuestionSuccess:
      state.questionReducer && state.questionReducer.createQuestionSuccess,
    createQuestionError:
      state.questionReducer && state.questionReducer.createQuestionError,
    updateQuestionSuccess:
      state.questionReducer && state.questionReducer.updateQuestionSuccess,
    updateQuestionError:
      state.questionReducer && state.questionReducer.updateQuestionError
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getQuestion,
      createQuestion,
      updateQuestion,
      clearQuestionsFlag
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(ManageQuestion)));
