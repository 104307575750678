import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";
import {
  getAllEmailConfiguration,
  deleteEmailConfiguration,
  clearEmailConfigurationFlag
} from "../../redux/actions/EmailConfigurationAction";
import Snackbar from "components/Snackbar/SnackbarContent";
import DeleteDialog from "components/DeleteDialog/DeleteDialog";

class EmailConfigurationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackBar: false,
      response: "",
      snackBarVariant: "success",
      openDialog: false,
      uuid: ""
    };
  }

  componentDidMount() {
    const { client, getAllEmailConfiguration } = this.props;
    getAllEmailConfiguration(client);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      clearEmailConfigurationFlag,
      deleteEmailConfigurationSuccess,
      deleteEmailConfigurationError
    } = props;

    if (deleteEmailConfigurationSuccess) {
      clearEmailConfigurationFlag();
      return {
        openSnackBar: true,
        response: "Email Configuration deleted successfully.",
        snackBarVariant: "success"
      };
    }

    if (deleteEmailConfigurationError) {
      clearEmailConfigurationFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, Please try again later!",
        snackBarVariant: "error"
      };
    }

    return null;
  }

  createConfiguration = () => {
    this.props.history.push("/admin/manage-email-configuration");
  };

  updateConfiguration = uuid => {
    this.props.history.push(`/admin/manage-email-configuration?id=${uuid}`, {
      uuid: uuid,
      isEdit: true
    });
  };

  openDeleteDialog = uuid => {
    this.setState({
      openDialog: true,
      uuid: uuid
    });
  };

  deleteConfiguration = uuid => {
    const { deleteEmailConfiguration, client } = this.props;
    deleteEmailConfiguration({ uuid }, client);
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false
    });
  };

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== "undefined") {
      if (typeof content === "object" && content !== null && content.key) {
        return String(content.key)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      } else {
        return String(content)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      }
    }

    return true;
  };

  render() {
    const columns = [
      {
        id: "id",
        Header: "No.",
        width: 130,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        },
        Cell: row => (
          <div style={{ textAlign: "center", fontSize: 16 }}>
            {row.index + 1}
          </div>
        )
      },
      {
        id: "hostname",
        Header: "Host Name",
        accessor: "hostname",
        filterable: false,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        id: "server_ip",
        Header: "Server IP Address",
        accessor: "server_ip",
        filterable: false,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        id: "port",
        Header: "Port",
        accessor: "port",
        filterable: false,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        id: "smtp_details",
        Header: "SMTP Details",
        accessor: "port",
        filterable: false,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        id: "encryption_type",
        Header: "Encryption Type",
        accessor: "encryption_type",
        filterable: false,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        id: "sender_name",
        Header: "Sender Name",
        accessor: "sender_name",
        filterable: false,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        Header: "Action",
        filterable: false,
        sortable: false,
        width: 100,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        },
        Cell: row => (
          <div
            style={{
              whiteSpace: "unset",
              cursor: "pointer",
              textDecoration: "underline",
              color: "#3f51b5",
              display: "flex"
            }}
          >
            <Tooltip title="Edit">
              <IconButton
                onClick={() => this.updateConfiguration(row.original.uuid)}
              >
                <EditIcon className={classes.iconEdit} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove">
              <IconButton
                onClick={() => this.openDeleteDialog(row.original.uuid)}
              >
                <DeleteIcon className={classes.iconRemove} />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    ];
    const { classes } = this.props;
    const {
      openSnackBar,
      snackBarVariant,
      response,
      uuid,
      openDialog
    } = this.state;
    return (
      <>
        <Snackbar
          open={openSnackBar}
          handleClose={this.handleCloseSnackbar}
          variant={snackBarVariant}
          message={response}
        />
        <DeleteDialog
          deleteIem={this.deleteConfiguration}
          uuid={uuid}
          open={openDialog}
          handleClose={this.handleCloseDialog}
        />
        <div className={classes.dFlex}>
          <Button color="info" onClick={this.createConfiguration}>
            {" "}
            Add Email Configuration{" "}
          </Button>
        </div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <EmailIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Email Configuration</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  className="-highlight"
                  noDataText="No records matching the provided criteria"
                  columns={columns}
                  data={this.props.allEmailConfigurationData}
                  defaultPageSize={15}
                  defaultFilterMethod={this.filterCaseInsensitive}
                ></ReactTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
EmailConfigurationDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    allEmailConfigurationData:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.allEmailConfigurationData,
    getAllEmailConfigurationSuccess:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.getAllEmailConfigurationSuccess,
    deleteEmailConfigurationSuccess:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.deleteEmailConfigurationSuccess,
    deleteEmailConfigurationError:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.deleteEmailConfigurationError
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllEmailConfiguration,
      clearEmailConfigurationFlag,
      deleteEmailConfiguration
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(EmailConfigurationDetails)));
