import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { logIn, clearLoginFlag } from "../../redux/actions/LoginActions";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimation: "cardHidden",
      email: "",
      passWord: "",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        cardAnimation: "",
      });
    });    
  }

  static getDerivedStateFromProps(props, state) {
    const { clearLoginFlag, loginSuccess } = props;
    if (loginSuccess) {
      clearLoginFlag();
      props.history.push("/admin/dashboard");
    }
    return null;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  goToDashboard = () => {
    const { email, passWord } = this.state;
    const { logIn, client } = this.props;

    const logInData = {
      email: email,
      password: passWord,
    };
    logIn(logInData, client);
  };

  render() {
    const { classes, loginError } = this.props;
    const { cardAnimation, email, passWord } = this.state;
    return (
      <div>
        <div
          className={classes.container}
          style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes[cardAnimation]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="info"
                  >
                    <h4
                      className={classes.cardTitle}
                      style={{
                        padding: "20px 0",
                        fontSize: "1.5rem",
                        fontWeight: "500",
                      }}
                    >
                      Log in
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <TextField
                      id="standard-password-input"
                      fullWidth
                      label="Email"
                      type="email"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      margin="normal"
                    />

                    <TextField
                      id="standard-password-input"
                      fullWidth
                      label="Password"
                      type="password"
                      name="passWord"
                      margin="normal"
                      value={passWord}
                      onChange={this.handleChange}
                    />
                    {loginError && (
                      <p className={classes.loginError}>
                        {" "}
                        Invalid Email and Password
                      </p>
                    )}
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      color="info"
                      simple
                      size="lg"
                      block
                      onClick={this.goToDashboard}
                    >
                      Let{"'"}s Go
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loginSuccess: state.logInReducer && state.logInReducer.loginSuccess,
    loginData: state.logInReducer && state.logInReducer.loginData,
    loginError: state.logInReducer && state.logInReducer.loginError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logIn,
      clearLoginFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(LoginPage)));
