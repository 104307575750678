import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
    background: "#0097a7 !important",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  loginError: {
    marginTop: "20px",
    textAlign: "center",
    color: "red",
  },
  divFlex: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  customCardHeader: {
    background: "#0097a7",
    textAlign: "center",
    color: "#fff",
    fontSize: "1rem",
    paddingTop: 0,
    paddingBottom: 0
  },
  customAppointmentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  patientDetails: {
    "& h6": {
      fontSize: "13px",
    },
  },
  answer: {
    color: "#8b8a8a",
  },
  customButton: {
    color: "#515050",
    lineHeight: '1',
    fontSize: '0.8rem',
    "&:hover": {
      backgroundColor: '#515050',
      color: '#fff'
    }
  },
});

export default loginPageStyle;
