import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";
import {
  getAllEmailTemplate,
  deleteEmailTemplate,
  clearEmailTemplateFlag
} from "../../redux/actions/EmailTemplateAction";
import Snackbar from "components/Snackbar/SnackbarContent";
import DeleteDialog from "components/DeleteDialog/DeleteDialog";

class EmailTemplateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackBar: false,
      response: "",
      snackBarVariant: "success"
    };
  }

  componentDidMount() {
    const { client, getAllEmailTemplate } = this.props;
    getAllEmailTemplate(client);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      clearEmailTemplateFlag,
      deleteEmailTemplateSuccess,
      deleteEmailTemplateError
    } = props;

    if (deleteEmailTemplateSuccess) {
      clearEmailTemplateFlag();
      return {
        openSnackBar: true,
        response: "Email Template deleted successfully.",
        snackBarVariant: "success"
      };
    }

    if (deleteEmailTemplateError) {
      clearEmailTemplateFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, Please try again later!",
        snackBarVariant: "error"
      };
    }

    return null;
  }

  createTemplate = () => {
    this.props.history.push("/admin/manage-email-template");
  };

  updateTemplate = uuid => {
    this.props.history.push(`/admin/manage-email-template?id=${uuid}`, {
      uuid: uuid,
      isEdit: true
    });
  };

  openDeleteDialog = uuid => {
    this.setState({
      openDialog: true,
      uuid: uuid
    });
  };

  deleteTemplate = uuid => {
    const { deleteEmailTemplate, client } = this.props;
    deleteEmailTemplate({ uuid }, client);
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false
    });
  };

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== "undefined") {
      if (typeof content === "object" && content !== null && content.key) {
        return String(content.key)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      } else {
        return String(content)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      }
    }

    return true;
  };

  render() {
    const columns = [
      {
        id: "id",
        Header: "No.",
        width: 130,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        },
        Cell: row => (
          <div style={{ textAlign: "center", fontSize: 16 }}>
            {row.index + 1}
          </div>
        )
      },
      {
        id: "name",
        Header: "Template Name",
        accessor: "name",
        filterable: false,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        id: "subject",
        Header: "Subject",
        accessor: "subject",
        filterable: false,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        Header: "Action",
        filterable: false,
        sortable: false,
        width: 100,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        },
        Cell: row => (
          <div
            style={{
              whiteSpace: "unset",
              cursor: "pointer",
              textDecoration: "underline",
              color: "#3f51b5",
              display: "flex"
            }}
          >
            <Tooltip title="Edit">
              <IconButton
                onClick={() => this.updateTemplate(row.original.uuid)}
              >
                <EditIcon className={classes.iconEdit} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove">
              <IconButton
                onClick={() => this.openDeleteDialog(row.original.uuid)}
              >
                <DeleteIcon className={classes.iconRemove} />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    ];
    const { classes } = this.props;
    const {
      openSnackBar,
      snackBarVariant,
      response,
      uuid,
      openDialog
    } = this.state;
    return (
      <>
        <Snackbar
          open={openSnackBar}
          handleClose={this.handleCloseSnackbar}
          variant={snackBarVariant}
          message={response}
        />
        <DeleteDialog
          deleteIem={this.deleteTemplate}
          uuid={uuid}
          open={openDialog}
          handleClose={this.handleCloseDialog}
        />

        <div className={classes.dFlex}>
          <Button color="info" onClick={this.createTemplate}>
            {" "}
            Add Email Template{" "}
          </Button>
        </div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <EmailIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Email Template</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  className="-highlight"
                  noDataText="No records matching the provided criteria"
                  columns={columns}
                  data={this.props.allEmailTemplateData}
                  defaultPageSize={15}
                  defaultFilterMethod={this.filterCaseInsensitive}
                ></ReactTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
EmailTemplateDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    allEmailTemplateData:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.allEmailTemplateData,
    getAllEmailTemplateSuccess:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.getAllEmailTemplateSuccess,
    deleteEmailTemplateSuccess:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.deleteEmailTemplateSuccess,
    deleteEmailTemplateError:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.deleteEmailTemplateError
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAllEmailTemplate,
      clearEmailTemplateFlag,
      deleteEmailTemplate
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(EmailTemplateDetails)));
