import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
// import { logIn, clearLoginFlag } from "../../redux/actions/LoginActions";

class PatientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.patientDetails}>
        <GridContainer justify="space-evenly">
          <GridItem xs={12} sm={12} md={5}>
            <Card>
              <CardHeader className={classes.customCardHeader}>
                <h4> Personal Details </h4>
              </CardHeader>
              <CardBody>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Name</h6>
                  <h6 className={classes.answer}>Test User</h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Email</h6>
                  <h6 className={classes.answer}>user@gmail.com</h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Phone</h6>
                  <h6 className={classes.answer}>+1 (121) 212-1212</h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Age</h6>
                  <h6 className={classes.answer}> 36 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Gender</h6>
                  <h6 className={classes.answer}> Female </h6>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader
                className={
                  classes.customCardHeader +
                  " " +
                  classes.customAppointmentHeader
                }
              >
                <h4> Appointment Details </h4>
                <Button
                  color="white"
                  size="sm"
                  className={classes.customButton}
                >
                  Schedule
                </Button>
              </CardHeader>
              <CardBody>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Appointment Type </h6>
                  <h6 className={classes.answer}> Video Call </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Date</h6>
                  <h6 className={classes.answer}>10/02/2020</h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Time</h6>
                  <h6 className={classes.answer}> 8:00 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Status </h6>
                  <h6 className={classes.answer} style={{ color: "green" }}>
                    Done
                  </h6>
                </div>
                <hr />
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Appointment Type </h6>
                  <h6 className={classes.answer}> Video Call </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Date</h6>
                  <h6 className={classes.answer}>15/03/2020</h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Time</h6>
                  <h6 className={classes.answer}> 10:00 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Status </h6>
                  <h6 className={classes.answer} style={{ color: "green" }}>
                    {" "}
                    Done{" "}
                  </h6>
                </div>
                <hr />
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Appointment Type </h6>
                  <h6 className={classes.answer}> Video Call </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Date</h6>
                  <h6 className={classes.answer}>15/06/2020</h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Time</h6>
                  <h6 className={classes.answer}> 12:00 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Status </h6>
                  <h6 className={classes.answer} style={{ color: "red" }}>
                    Pending
                  </h6>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            <Card>
              <CardHeader className={classes.customCardHeader}>
                <h4> Assessment Details </h4>
              </CardHeader>
              <CardBody>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Score</h6>
                  <h6 className={classes.answer}> 60 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Result</h6>
                  <h6 className={classes.answer}> Positive </h6>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className={classes.customCardHeader}>
                <h4> Question Answer List </h4>
              </CardHeader>
              <CardBody>
                <div>
                  <h6 className={classes.fontWeightBold}>
                    Are you experiencing any of these symptoms?
                  </h6>
                  <h6 className={classes.answer}>
                    {" "}
                    - Fever, Chills or sweating
                  </h6>
                  <h6 className={classes.answer}> - Sore throat</h6>
                  <h6 className={classes.answer}> - Vomiting or diarrhea</h6>
                </div>
                <div>
                  <h6 className={classes.fontWeightBold}>
                    Do any of these apply to you?
                  </h6>
                  <h6 className={classes.answer}> - None</h6>
                </div>
                <div>
                  <h6 className={classes.fontWeightBold}>
                    In the last 14 days, have you traveled internationally?
                  </h6>
                  <h6 className={classes.answer}>
                    {" "}
                    - I have traveled internationally
                  </h6>
                </div>
                <div>
                  <h6 className={classes.fontWeightBold}>
                    In the last 14 days, have you been in an area where COVID‑19
                    is widespread?
                  </h6>
                  <h6 className={classes.answer}>
                    {" "}
                    - I have visited an area where COVID-19 is widespread
                  </h6>
                </div>
                <div>
                  <h6 className={classes.fontWeightBold}>
                    In the last 14 days, what is your exposure to others who are
                    known to have COVID‑19?
                  </h6>
                  <h6 className={classes.answer}>- I've had no exposure </h6>
                </div>
                <div>
                  <h6 className={classes.fontWeightBold}>
                    Do you work in a medical facility?
                  </h6>
                  <h6 className={classes.answer}>
                    {" "}
                    - I plan to work in a hospital or other care facility in the
                    next 14 days
                  </h6>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className={classes.customCardHeader +
                  " " +
                  classes.customAppointmentHeader}>
                <h4> Test Kit Result </h4>
                <Button
                  color="white"
                  size="sm"
                  className={classes.customButton}
                >
                  Add 
                </Button>
              </CardHeader>
              <CardBody>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Test Kit</h6>
                  <h6 className={classes.answer}> COVID-19 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Date</h6>
                  <h6 className={classes.answer}> 20/02/2020 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Result </h6>
                  <h6 className={classes.answer} style={{ color: 'green' }}> Negative </h6>
                </div>
                <hr />
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Test Kit</h6>
                  <h6 className={classes.answer}> COVID-19 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}>Date</h6>
                  <h6 className={classes.answer}> 10/03/2020 </h6>
                </div>
                <div className={classes.divFlex}>
                  <h6 className={classes.fontWeightBold}> Result </h6>
                  <h6 className={classes.answer} style={{ color: 'green' }}> Negative </h6>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PatientDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PatientDetails);
