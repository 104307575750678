import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import Apps from "@material-ui/icons/Apps";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";

const simpleButtons = [
  { color: "success", icon: Edit, tooltip: "Edit", url:"/manage-appointment" },
  { color: "danger", icon: Close, tooltip: "Remove" },
].map((prop, key) => {
  return (
    <Tooltip title={prop.tooltip} placement="bottom" key={prop.color + key}>
      <Button
        color={prop.color}
        simple
        key={key}
        style={{
          margin: "0 0 0 5px",
          padding: "5px",
        }}
        href={prop.url}
      >
        <prop.icon
          style={{
            verticalAlign: "middle",
            width: "17px",
            height: "17px",
            top: "-1px",
            position: "relative",
          }}
        />
      </Button>
    </Tooltip>
  );
});

class AppointmentDetails extends Component {
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <AccessTimeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Appointment Details</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  "#",
                  "Name",
                  "Appointment Type",
                  "Date",
                  "Time",
                  "Status",
                  "Action",
                ]}
                tableData={[
                  [
                    "1",
                    "Test User",
                    "Video Call",
                    "20/01/2020",
                    "10:00",
                    "Pending",
                    simpleButtons,
                  ],
                  [
                    "2",
                    "Demo User",
                    "Video Call",
                    "02/02/2020",
                    "12:00",
                    "Pending",
                    simpleButtons,
                  ],
                  [
                    "3",
                    "User1",
                    "Video Call",
                    "05/03/2020",
                    "10:00",
                    "Pending",
                    simpleButtons,
                  ],
                  [
                    "4",
                    "User2",
                    "Video Call",
                    "10/07/2020",
                    "8:00",
                    "Pending",
                    simpleButtons,
                  ],
                ]}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  // classes.right
                ]}
                customClassesForCells={[0, 6]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  // classes.right
                ]}
                customHeadClassesForCells={[0, 6]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
AppointmentDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AppointmentDetails);
