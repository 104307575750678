import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import EmailIcon from "@material-ui/icons/Email";
import MenuBook from "@material-ui/icons/ListAlt";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import Dashboard from "views/Dashboard/Dashboard.js";
import PatientList from "views/Patient/PatientList";
import PatientDetails from "views/Patient/PatientDetails.js";
import ManageSubscription from "views/Subscription/ManageSubscription.js";
import SubscriptionDetails from "views/Subscription/SubscriptionDetails.js";
import ManageEmailTemplate from "views/Email/ManageEmailTemplate.js";
import ManageEmailConfiguration from "views/Email/ManageEmailConfiguration.js";
import EmailConfigurationList from "views/Email/EmailConfigurationList.js";
import EmailTemplateList from "views/Email/EmailTemplateList.js";
import CMSList from "views/CMS/CMSList.js";
import ManageCMS from "views/CMS/ManageCMS.js";
import Questions from "views/Questions/QuestionsList.js";
import ManageQuestion from "views/Questions/ManageQuestion.js";
import AppointmentDetails from "views/Appointments/AppointmentDetails.js";
import ManageAppointment from "views/Appointments/ManageAppointment.js";
import VideoCall from "./VideoCall.js";
import Chat from "views/Chat/Chat.js";
import ResultList from "views/Result/ResultList.js";
import ManageResult from "views/Result/ManageResult.js";

const userData = localStorage.getItem("email");

if (userData == "user@gmail.com") {
  var dashRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Users",
      icon: PermIdentityIcon,
      state: "pageCollapse",
      views: [
        {
          path: "/patient-list",
          name: "User List",
          mini: "UL",
          component: PatientList,
          layout: "/admin",
        },
        {
          path: "/patient-details",
          name: "User Details",
          mini: "UD",
          component: PatientDetails,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Schedule Appointment",
      icon: AccessTimeIcon,
      state: "componentsCollapse",
      views: [
        {
          path: "/appointments",
          name: "Appointments",
          mini: "AP",
          component: AppointmentDetails,
          layout: "/admin",
        },
        {
          path: "/manage-appointment",
          name: "Manage Appointment",
          mini: "MA",
          component: ManageAppointment,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Communication",
      icon: QuestionAnswerIcon,
      state: "questionCollapse",
      views: [
        {
          path: "/video-call",
          name: "Video Call",
          mini: "VC",
          component: VideoCall,
          layout: "/admin",
        },
        {
          path: "/chat",
          name: "Chat",
          mini: "C",
          component: Chat,
          layout: "/admin",
        },
      ],
    },
  ];
} else {
  var dashRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Patient",
      icon: PermIdentityIcon,
      state: "pageCollapse",
      views: [
        {
          path: "/patient-list",
          name: "Patient List",
          mini: "PL",
          component: PatientList,
          layout: "/admin",
        },
        {
          path: "/patient-details",
          name: "Patient Details",
          mini: "PD",
          component: PatientDetails,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Subscription",
      icon: Apps,
      state: "componentsCollapse",
      views: [
        {
          path: "/subscription-details",
          name: "Subscription List",
          mini: "SD",
          component: SubscriptionDetails,
          layout: "/admin",
        },
        {
          path: "/manage-subscription",
          name: "Manage Subscription",
          mini: "AS",
          component: ManageSubscription,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Email",
      icon: EmailIcon,
      state: "formsCollapse",
      views: [
        {
          path: "/email-template",
          name: "Email Template",
          mini: "ET",
          component: EmailTemplateList,
          layout: "/admin",
        },
        {
          path: "/manage-email-template",
          name: "Manage Email Template",
          mini: "ET",
          component: ManageEmailTemplate,
          layout: "/admin",
        },
        {
          path: "/email-configuration",
          name: "Email Configuration",
          mini: "EC",
          component: EmailConfigurationList,
          layout: "/admin",
        },
        {
          path: "/manage-email-configuration",
          name: "Manage Email Configuration",
          mini: "EC",
          component: ManageEmailConfiguration,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "CMS",
      icon: MenuBook,
      state: "tablesCollapse",
      views: [
        {
          path: "/cms-list",
          name: "CMS List",
          mini: "CL",
          component: CMSList,
          layout: "/admin",
        },
        {
          path: "/manage-cms",
          name: "Manage CMS",
          mini: "MC",
          component: ManageCMS,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Question",
      icon: QuestionAnswerIcon,
      state: "mapsCollapse",
      views: [
        {
          path: "/questions",
          name: "Question List",
          mini: "QL",
          component: Questions,
          layout: "/admin",
        },
        {
          path: "/manage-question",
          name: "Manage Question",
          mini: "MQ",
          component: ManageQuestion,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Result",
      icon: LibraryBooksIcon,
      state: "questionCollapse",
      views: [
        {
          path: "/result-list",
          name: "Result List",
          mini: "RL",
          component: ResultList,
          layout: "/admin",
        },
        {
          path: "/manage-result",
          name: "Manage Result",
          mini: "MR",
          component: ManageResult,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "Communication",
      icon: QuestionAnswerIcon,
      state: "questionCollapse",
      views: [
        {
          path: "/video-call",
          name: "Video Call",
          mini: "VC",
          component: VideoCall,
          layout: "/admin",
        },
        {
          path: "/chat",
          name: "Chat",
          mini: "C",
          component: Chat,
          layout: "/admin",
        },
      ],
    },
  ];
}
export default dashRoutes;
