import React, { Component } from "react";
import Chat from "twilio-chat";
import { Chat as ChatUI } from "@progress/kendo-react-conversational-ui";
import { postRequestWithOutToken } from "helper/Helper";

class ChatApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      messages: [],
    };

    this.user = {
      id: props.username,
      name: props.username,
    };

    this.setupChatClient = this.setupChatClient.bind(this);
    this.messagesLoaded = this.messagesLoaded.bind(this);
    this.messageAdded = this.messageAdded.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  async componentDidMount() {
    //   fetch('http://localhost:3003/chat/token', {
    //     headers: { 'Content-Type': 'application/json' },
    //     method: 'POST',
    //     body: JSON.stringify({"identity": encodeURIComponent(this.props.username)})
    //   })
    //     .then(res => res.json())
    //     .then(data => Chat.create(data.token))
    //     .then(this.setupChatClient)
    //     .catch(this.handleError);
    // }
    const baseUrl = process.env.REACT_APP_VIDEO_CHAT_BASE_URI;
    const temp = await postRequestWithOutToken(`${baseUrl}chat/token`, {
      identity: encodeURIComponent(this.props.username),
    });
    Chat.create(temp.data.token)
      .then((res) => this.setupChatClient(res))
      .catch((error) => {
        console.log(error);
      });
  }

  handleError(error) {
    console.error(error);
    this.setState({
      error: "Could not load chat.",
    });
  }

  setupChatClient(client) {
    this.client = client;
    this.client
      .getChannelByUniqueName("general3")
      .then((channel) => channel)
      .catch((error) => {
        if (error.body.code === 50300) {
          return this.client.createChannel({ uniqueName: "general3" });
        } else {
          this.handleError(error);
        }
      })
      .then((channel) => {
        this.channel = channel;
        return this.channel.join().catch(() => {});
      })
      .then(() => {
        this.setState({ isLoading: false });
        this.channel.getMessages().then(this.messagesLoaded);
        this.channel.on("messageAdded", this.messageAdded);
      })
      .catch(this.handleError);
  }

  twilioMessageToKendoMessage(message) {
    return {
      text: message.body,
      author: { id: message.author, name: message.author },
      timestamp: message.timestamp,
    };
  }

  messagesLoaded(messagePage) {
    this.setState({
      messages: messagePage.items.map(this.twilioMessageToKendoMessage),
    });
  }

  messageAdded(message) {
    this.setState((prevState) => ({
      messages: [
        ...prevState.messages,
        this.twilioMessageToKendoMessage(message),
      ],
    }));
  }

  sendMessage(event) {
    this.channel.sendMessage(event.message.text);
  }

  componentWillUnmount() {
    this.client.shutdown();
  }

  render() {
    if (this.state.error) {
      return <p>{this.state.error}</p>;
    } else if (this.state.isLoading) {
      return <p>Loading chat...</p>;
    }
    return (
      <ChatUI
        user={this.user}
        messages={this.state.messages}
        onMessageSend={this.sendMessage}
        width={500}
      />
    );
  }
}

export default ChatApp;
