import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Button from "components/CustomButtons/Button.js";

const simpleButtons = [
  { color: "success", icon: Edit, tooltip: "Edit" },
  { color: "danger", icon: Close, tooltip: "Remove" },
].map((prop, key) => {
  return (
    <Tooltip title={prop.tooltip} placement="bottom" key={prop.color + key}>
      <Button
        color={prop.color}
        simple
        key={key}
        style={{
          margin: "0 0 0 5px",
          padding: "5px",
        }}
        href={prop.url}
      >
        <prop.icon
          style={{
            verticalAlign: "middle",
            width: "17px",
            height: "17px",
            top: "-1px",
            position: "relative",
          }}
        />
      </Button>
    </Tooltip>
  );
});

class ResultList extends Component {
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <LibraryBooksIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}> Result List </h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHead={[
                  "#",
                  "Name",
                  "Test Kit",
                  "Result",
                  "Date",
                  "Doctor",
                  "Action",
                ]}
                tableData={[
                  [
                    "1",
                    "Patient1",
                    "COVID-19",
                    "Positive",
                    "10/02/2020",
                    "Dr. George",
                    simpleButtons,
                  ],
                  [
                    "2",
                    "Patient2",
                    "COVID-19",
                    "Negative",
                    "10/02/2020",
                    "Dr. Harry",
                    simpleButtons,
                  ],
                  [
                    "3",
                    "Patient3",
                    "COVID-19",
                    "Negative",
                    "10/02/2020",
                    "Dr. Harry",
                    simpleButtons,
                  ],
                  [
                    "4",
                    "Patient4",
                    "COVID-19",
                    "Negative",
                    "10/02/2020",
                    "Dr. George",
                    simpleButtons,
                  ],
                ]}
                customCellClasses={[
                  classes.center,
                  classes.right,
                ]}
                customClassesForCells={[0, 6]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                ]}
                customHeadClassesForCells={[0, 6]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
ResultList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ResultList);
