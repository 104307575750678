import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "components/CustomButtons/Button.js";

class DeleteDialog extends Component {
 
  deleteItem = () => {
    const { uuid, deleteIem, handleClose } = this.props;
    deleteIem(uuid)        
    handleClose()
  }

  render() {
    const { open, handleClose } = this.props;    
    return (
      <div>
        <Dialog
          open={open || false}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Delete Item
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You want to delete this item ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
            onClick={this.deleteItem} 
            color="info">
              Yes
            </Button>
            <Button onClick={handleClose} color="info" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default DeleteDialog;
