import React, { Component } from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
// import MailOutline from "@material-ui/icons/MailOutline";
// import Check from "@material-ui/icons/Check";
// import Clear from "@material-ui/icons/Clear";
// import Contacts from "@material-ui/icons/Contacts";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddIcon from '@material-ui/icons/Add';
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { TextField, MenuItem, Tooltip, IconButton } from "@material-ui/core";

class AddSubscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SubscriptionType: '',
            Subscriptions: ['Monthly', 'Quarterly', 'Yearly'],
            Subscription_name: '',
            Subscription_price: '',
            checkedDiscount: false,
            discount_percentage: '',
            discount_price: '',
            FeatureTypes: ['Included', 'Not Included'],
            features: [
                {
                    isIncluded: '',
                    value: ''
                }
            ]
        }
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    answerChange = (e) => {
        this.setState({
            SubscriptionType: e.target.value
        })
    }

    handleDiscountChange = (e) => {
        this.setState({
            checkedDiscount: e.target.checked
        })
    }

    addFeature = () => {
        const { features } = this.state;

        let feature = {
            isIncluded: false,
            value: ''
        }

        features.push(feature)

        this.setState({ features })

    }

    render() {
        const { classes } = this.props;
        const { Subscriptions, Subscription_name, SubscriptionType, Subscription_price, checkedDiscount, discount_percentage, discount_price, FeatureTypes, features } = this.state;
        return (
            <>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="info" text>
                                <CardText color="info">
                                    <h4 className={classes.cardTitle}>
                                        {/* Add Subscription */}
                                        Subscription Package Information
                                </h4>
                                </CardText>
                            </CardHeader>
                            <CardBody className={classes.subscriptionCard}>
                                <form>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal} style={{ paddingTop: 0 }}>
                                                Subscription Type
                                        </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={10}>
                                            <TextField
                                                fullWidth
                                                select
                                                open={this.open}
                                                onClose={this.handleClose}
                                                variant="outlined"
                                                label="Please Select Subscription Type"
                                                value={SubscriptionType}
                                                onChange={this.answerChange}
                                            >
                                                {Subscriptions.map((val) =>
                                                    <MenuItem value={val}>{val}</MenuItem>
                                                )}
                                            </TextField>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Subscription Name
                                        </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={10}>
                                            <TextField
                                                fullWidth
                                                label="Please Enter Subscription Name"
                                                name="Subscription_name"
                                                margin="normal"
                                                variant="outlined"
                                                value={Subscription_name}
                                                onChange={this.handleChange}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Subscription Price
                                        </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={10}>
                                            <TextField
                                                fullWidth
                                                label="Please Enter Subscription Price"
                                                name="Subscription_price"
                                                margin="normal"
                                                variant="outlined"
                                                value={Subscription_price}
                                                onChange={this.handleChange}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={2}>
                                            <FormLabel className={classes.labelHorizontal}>
                                                Discount
                                        </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={10}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedDiscount}
                                                        onChange={(e) => this.handleDiscountChange(e)}
                                                        value="checkedDiscount"
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                        style={{
                                                            paddingTop: 20
                                                        }}
                                                    />
                                                }
                                            // label="Discount"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    {checkedDiscount ?
                                        <>
                                            <GridContainer>
                                                <GridItem xs={12} sm={2}>
                                                    <FormLabel className={classes.labelHorizontal}>
                                                        Discount Percentage
                                                    </FormLabel>
                                                </GridItem>
                                                <GridItem xs={12} sm={10}>
                                                    <TextField
                                                        fullWidth
                                                        label="Please Enter Discount Percentage"
                                                        name="discount_percentage"
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={discount_percentage}
                                                        onChange={this.handleChange}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem xs={12} sm={2}>
                                                    <FormLabel className={classes.labelHorizontal}>
                                                        Price after Discount
                                                    </FormLabel>
                                                </GridItem>
                                                <GridItem xs={12} sm={10}>
                                                    <TextField
                                                        fullWidth
                                                        name="discount_price"
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={discount_price}
                                                        onChange={this.handleChange}
                                                        disabled={true}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </>
                                        :
                                        <> </>
                                    }
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="info" text>
                                <CardText color="info">
                                    <h4 className={classes.cardTitle}>
                                        {/* Add Subscription */}
                                        Subscription Package Features Information
                                    </h4>
                                </CardText>
                            </CardHeader>
                            <CardBody className={classes.subscriptionCard}>
                                <form>
                                    <GridContainer>
                                        <GridItem sm={12}>
                                            {features.map((feature, index) =>
                                                <GridContainer className={classes.featureInfoGrid}>
                                                    <GridItem sm={6}>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={3}>
                                                                <FormLabel className={classes.labelHorizontal} style={{ paddingTop: 0 }}>
                                                                    Feature Type
                                                                </FormLabel>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={9}>
                                                                <TextField
                                                                    fullWidth
                                                                    select
                                                                    variant="outlined"
                                                                    // label="Please Select Feature Type"
                                                                    value={feature.isIncluded}
                                                                    onChange={(e) => {
                                                                        features[index].isIncluded = e.target.value
                                                                        this.setState({ features })
                                                                    }}
                                                                >
                                                                    {FeatureTypes.map((val) =>
                                                                        <MenuItem value={val}>{val}</MenuItem>
                                                                    )}
                                                                </TextField>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </GridItem>
                                                    <GridItem sm={6}>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={3}>
                                                                <FormLabel className={classes.labelHorizontal} style={{ paddingTop: 0 }}>
                                                                    Subscription Feature
                                                                </FormLabel>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={9}>
                                                                <TextField
                                                                    fullWidth
                                                                    // label="Please Enter Subscription Feature"
                                                                    className={classes.textField}
                                                                    // margin="normal"
                                                                    variant="outlined"
                                                                    value={feature.value}
                                                                    onChange={(e) => {
                                                                        features[index].value = e.target.value
                                                                        this.setState({ features })
                                                                    }}
                                                                />
                                                            </GridItem>
                                                        </GridContainer>
                                                    </GridItem>
                                                </GridContainer>
                                            )}
                                            <GridContainer>
                                                <GridItem sm={2} style={{ textAlign: 'center' }}>
                                                    <Tooltip title="Add">
                                                        <IconButton
                                                            onClick={this.addFeature}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div style={{ textAlign: 'right' }}>
                    <Button type="submit" variant="contained" size="small" color="info" style={{ fontSize: '0.95rem', marginTop: 30 }} onClick={this.handleSubmit}>
                        SAVE
                    </Button>
                </div>
            </>
        );
    }
}
AddSubscription.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddSubscription);
