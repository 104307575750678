import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField } from "@material-ui/core";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import {
    DatePicker,    
    MuiPickersUtilsProvider,
  } from "@material-ui/pickers";
  import MomentUtils from "@date-io/moment";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button.js";
// import Snackbar from "components/Snackbar/SnackbarContent";
import CardIcon from "components/Card/CardIcon";

class ManageResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      testKit: "",
      result: "",
      date: new Date(),
      resultFile: "",
      doctor: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  render() {
    const { classes } = this.props;
    const { name, testKit, result, date, resultFile, doctor } = this.state;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardIcon color="info">
                  <LibraryBooksIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}> Manage Result </h4>
              </CardHeader>
              <CardBody className={classes.subscriptionCard}>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Enter Patient Name"
                        type="text"
                        name="name"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={name}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Test Kit
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Enter Test Kit"
                        type="text"
                        name="testKit"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={testKit}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                      >
                        Result
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Enter Result"
                        type="text"
                        name="result"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={result}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                      >
                        Date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          inputVariant="outlined"
                          fullWidth
                          value={date}
                          margin="normal"
                          format="MM/DD/YYYY"
                          onChange={this.handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                      >
                        Assign Doctor
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        label="Enter Doctor Name"
                        fullWidth
                        type="text"
                        name="doctor"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={doctor}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                      >
                        Upload Result
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        type="file"
                        name="resultFile"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={resultFile}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="sm"
                  color="info"
                  style={{ fontSize: "0.95rem", marginTop: 30 }}
                  onClick={this.createCMS}
                >
                  SAVE
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
ManageResult.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageResult);
