import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import {
  getPatientData,
  clearPatientFlags
} from "../../redux/actions/PatientActions";

class PatientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: []
    };
  }

  componentDidMount() {
    const { getPatientData, client } = this.props;
    getPatientData(client);
  }

  static getDerivedStateFromProps(props, state) {
    const { getPatientSuccess, patientData } = props;
    if (getPatientSuccess) {
      return {
        post: patientData
      };
    }
    return null;
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== "undefined") {
      if (typeof content === "object" && content !== null && content.key) {
        return String(content.key)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      } else {
        return String(content)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      }
    }

    return true;
  };

  render() {
    const { classes } = this.props;
    const columns = [     
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: row => (
          <div
            style={{
              whiteSpace: "unset",
              cursor: "pointer",              
            }}
          >
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        style: { whiteSpace: "unset", cursor: "pointer" },
        Cell: row => (
          <div style={{ wordWrap: "break-word" }}>
            <a href={"mailto:" + row.value}>{row.value}</a>
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        Header: "Phone",
        accessor: "mobile_no",
        filterable: true,
        Cell: row => (
          <div style={{ whiteSpace: "unset", cursor: "pointer" }}>
            <a href={"tel://" + row.value}>{row.value}</a>
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      // {
      //   Header: "Address",
      //   accessor: "address",
      //   filterable: true,
      //   headerStyle: {
      //     fontWeight: "bold",
      //     paddingTop: 10,
      //     paddingBottom: 10,
      //     whiteSpace: "unset",
      //     fontSize: 14
      //   }
      // },
      {
        Header: "Score",
        accessor: "patient.score",
        filterable: true,
        id: "score",
        // width: 150,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        },
        Cell: row => (
          <div style={{ whiteSpace: "unset", textAlign: "center" }}>
             {row.value}
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "100") {
            return row[filter.id] === "100";
          }
          if (filter.value === "90") {
            return row[filter.id] >= 90 && row[filter.id] < 100;
          }
          if (filter.value === "80") {
            return row[filter.id] >= 80 && row[filter.id] < 90;
          }
          if (filter.value === "70") {
            return row[filter.id] >= 70 && row[filter.id] < 80;
          }
          if (filter.value === "60") {
            return row[filter.id] >= 60 && row[filter.id] < 70;
          }
          if (filter.value === "50") {
            return row[filter.id] >= 50 && row[filter.id] < 60;
          }
          if (filter.value === "40") {
            return row[filter.id] >= 40 && row[filter.id] < 50;
          }
          if (filter.value === "30") {
            return row[filter.id] >= 30 && row[filter.id] < 40;
          }
          if (filter.value === "20") {
            return row[filter.id] >= 20 && row[filter.id] < 30;
          }
          if (filter.value === "10") {
            return row[filter.id] >= 10 && row[filter.id] < 20;
          }
          if (filter.value === "0") {
            return row[filter.id] >= 0 && row[filter.id] < 10;
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">All</option>
            <option value="100">100</option>
            <option value="90"> 90 - 99</option>
            <option value="80"> 80 - 89</option>
            <option value="70"> 70 - 79</option>
            <option value="60"> 60 - 69</option>
            <option value="50"> 50 - 59</option>
            <option value="40"> 40 - 49</option>
            <option value="30"> 30 - 39</option>
            <option value="20"> 20 - 29</option>
            <option value="10"> 10 - 19</option>
            <option value="0"> 0 - 9</option>
          </select>
        )
      },
      {
        Header: "UserType",
        accessor: "is_guest",
        filterable: true,
        // width: 180,
        style: { whiteSpace: "unset", cursor: "pointer" },
        Cell: row => (
          <div style={{ wordWrap: "break-word" }}>
            {row.value === 0 ? "Registered User" : "Guest User"}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
      {
        Header: "Purchased Kit",
        accessor: "payments_count",
        filterable: true,
        style: { whiteSpace: "unset", cursor: "pointer" },
        // width: 150,
        Cell: row => <div style={{ wordWrap: "break-word" }}>{row.value > 0 ? "Yes" : "No"}</div>,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14
        }
      },
    ];
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PermIdentityIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>User Details</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                className="-highlight"
                noDataText="No Patient matching the provided criteria"
                columns={columns}
                data={this.state.post}
                defaultPageSize={25}
                defaultFilterMethod={this.filterCaseInsensitive}
                //getTrProps={onRowClick}
                // minRows={0}
              ></ReactTable>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
PatientDetails.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    patientData: state.patientReducer && state.patientReducer.patientData,
    getPatientSuccess:
      state.patientReducer && state.patientReducer.getPatientSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getPatientData,
      clearPatientFlags
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(PatientDetails)));
