import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
import EmailIcon from "@material-ui/icons/Email";
import { TextField, MenuItem } from "@material-ui/core";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
import {
  getEmailConfiguration,
  clearEmailConfigurationFlag,
  createEmailConfiguration,
  updateEmailConfiguration
} from "../../redux/actions/EmailConfigurationAction";
import Snackbar from "components/Snackbar/SnackbarContent";

class ManageEmailConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostname: "",
      server_ip: "",
      port: "",
      sender_email: "",
      sender_name: "",
      smtp_details: "",
      smtp_password: "",
      encryptionType: ["SSL", "TLS", "STARTTLS", "NONE"],
      encryption_type: "",
      uuid: props.location.state && props.location.state.uuid,
      isEdit: props.location.state && props.location.state.isEdit,
      openSnackBar: false,
      response: "",
      snackBarVariant: "error"
    };
  }

  componentDidMount() {
    const { getEmailConfiguration, client } = this.props;
    const { uuid } = this.state;
    uuid && getEmailConfiguration({ uuid }, client);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      emailConfigurationData,
      getEmailConfigurationSuccess,
      clearEmailConfigurationFlag,
      updateEmailConfigurationSuccess,
      updateEmailConfigurationError,
      createEmailConfigurationSuccess,
      createEmailConfigurationError
    } = props;

    if (getEmailConfigurationSuccess) {
      clearEmailConfigurationFlag();
      return {
        hostname: emailConfigurationData.hostname,
        server_ip: emailConfigurationData.server_ip,
        port: emailConfigurationData.port,
        sender_email: emailConfigurationData.sender_email,
        sender_name: emailConfigurationData.sender_name,
        smtp_details: emailConfigurationData.smtp_details,
        smtp_password: emailConfigurationData.smtp_password,
        encryption_type: emailConfigurationData.encryption_type
      };
    }

    if (createEmailConfigurationSuccess) {
      clearEmailConfigurationFlag();
      props.history.push("/admin/email-configuration");
    }

    if (createEmailConfigurationError) {
      clearEmailConfigurationFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, please try again!",
        snackBarVariant: "error"
      };
    }

    if (updateEmailConfigurationSuccess) {
      clearEmailConfigurationFlag();
      props.history.push("/admin/email-configuration");
    }

    if (updateEmailConfigurationError) {
      clearEmailConfigurationFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, please try again!",
        snackBarVariant: "error"
      };
    }

    return null;
  }

  createConfiguration = () => {
    const { createEmailConfiguration, client } = this.props;
    const {
      hostname,
      server_ip,
      port,
      sender_email,
      sender_name,
      smtp_details,
      smtp_password,
      encryption_type,
      encryptionType
    } = this.state;
    const createdData = {
      hostname,
      server_ip,
      port,
      sender_email,
      sender_name,
      smtp_details,
      smtp_password,
      encryption_type,
      encryptionType
    };
    createEmailConfiguration(createdData, client);
  };

  updateConfiguration = () => {
    const { updateEmailConfiguration, client } = this.props;
    const {
      uuid,
      hostname,
      server_ip,
      port,
      sender_email,
      sender_name,
      smtp_details,
      smtp_password,
      encryption_type,
      encryptionType
    } = this.state;
    const updatedData = {
      uuid,
      hostname,
      server_ip,
      port,
      sender_email,
      sender_name,
      smtp_details,
      smtp_password,
      encryption_type,
      encryptionType
    };
    updateEmailConfiguration(updatedData, client);
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false
    });
  };

  render() {
    const { classes } = this.props;
    const {
      hostname,
      server_ip,
      port,
      sender_email,
      sender_name,
      smtp_details,
      smtp_password,
      encryption_type,
      encryptionType,
      isEdit,
      openSnackBar,
      snackBarVariant,
      response
    } = this.state;
    return (
      <>
        <Snackbar
          open={openSnackBar}
          handleClose={this.handleCloseSnackbar}
          variant={snackBarVariant}
          message={response}
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardIcon color="info">
                  <EmailIcon />
                </CardIcon>
                {/* <CardText color="info">
                  <h4 className={classes.cardTitle}>Email Configuration</h4>
                </CardText> */}
              </CardHeader>
              <CardBody className={classes.subscriptionCard}>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Host Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Host Name"
                        type="text"
                        name="hostname"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={hostname}
                        onChange={e => this.handleChange(e)}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Server IP
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Server IP"
                        type="text"
                        name="server_ip"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={server_ip}
                        onChange={e => this.handleChange(e)}
                        className={classes.ml25}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Port
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Port"
                        type="text"
                        name="port"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={port}
                        onChange={e => this.handleChange(e)}
                        className={classes.ml25}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        SMTP User Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="SMTP User Name"
                        type="text"
                        name="smtp_details"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={smtp_details}
                        onChange={e => this.handleChange(e)}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        SMTP Password
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="SMTP Password"
                        type="password"
                        name="smtp_password"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={smtp_password}
                        onChange={e => this.handleChange(e)}
                        className={classes.ml25}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Encryption Type
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        select
                        fullWidth
                        name="encryption_type"
                        value={encryption_type}
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        onChange={e => this.handleChange(e)}
                        label="Select Encryption Type"
                      >
                        {encryptionType.map((val, index) => (
                          <MenuItem value={val} key={val + index}>
                            {val}
                          </MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Sender Mail
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Sender Mail"
                        type="text"
                        name="sender_email"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={sender_email}
                        onChange={e => this.handleChange(e)}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Sender Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Sender Name"
                        type="text"
                        name="sender_name"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={sender_name}
                        onChange={e => this.handleChange(e)}
                        className={classes.ml25}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                {isEdit ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="info"
                    style={{ fontSize: "0.95rem", marginTop: 30 }}
                    onClick={this.updateConfiguration}
                  >
                    UPDATE
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="info"
                    style={{ fontSize: "0.95rem", marginTop: 30 }}
                    onClick={this.createConfiguration}
                  >
                    Save
                  </Button>
                )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
ManageEmailConfiguration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    emailConfigurationData:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.emailConfigurationData,
    getEmailConfigurationSuccess:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.getEmailConfigurationSuccess,
    updateEmailConfigurationSuccess:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.updateEmailConfigurationSuccess,
    updateEmailConfigurationError:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.updateEmailConfigurationError,
    createEmailConfigurationSuccess:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.createEmailConfigurationSuccess,
    createEmailConfigurationError:
      state.emailConfigurationReducer &&
      state.emailConfigurationReducer.createEmailConfigurationSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getEmailConfiguration,
      clearEmailConfigurationFlag,
      createEmailConfiguration,
      updateEmailConfiguration
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(ManageEmailConfiguration)));
