import React, { Component } from "react";
import PropTypes from "prop-types";
// import ReactQuill from "react-quill";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, MenuItem } from "@material-ui/core";
// import Input from "@material-ui/core/Input";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
// import { withApollo } from "react-apollo";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class ManageEmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      appointmentType: "",
      date: new Date(),
      time: new Date(),
      types: ["video Call", "Chat"],
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleVariableChange = (e) => {
    this.setState({ appointmentType: e.target.value, display: true });
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  handleTimeChange = (time) => {
    this.setState({ time });
  };

  render() {
    const { classes } = this.props;
    const { name, appointmentType, date, time, types } = this.state;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitle}>Manage Appointment</h4>
                </CardText>
              </CardHeader>
              <CardBody className={classes.subscriptionCard}>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        id="filled-name-input"
                        type="text"
                        name="templateTitle"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        label="Please Enter Name"
                        value={name}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Appointment Type
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        select
                        fullWidth
                        name={appointmentType}
                        value={appointmentType}
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleVariableChange}
                        label="Select appointment type"
                      >
                        {types.map((val, index) => (
                          <MenuItem key={val + index} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          inputVariant="outlined"
                          fullWidth
                          value={date}
                          margin="normal"
                          format="MM/DD/YYYY"
                          onChange={this.handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Time
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <TimePicker
                          inputVariant="outlined"
                          fullWidth
                          value={time}
                          margin="normal"
                          onChange={this.handleTimeChange}
                        />
                      </MuiPickersUtilsProvider>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="info"
                  style={{ fontSize: "0.95rem", marginTop: 30 }}
                >
                  Save
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
ManageEmailTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManageEmailTemplate);
